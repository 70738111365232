<template lang="pug">
    router-link(:to="route" :class="{ 'alt' : alt }" :alt="title").link
        svg.icon.polygon
            use(xlink:href="#icon-polygon")
        svg.icon.arrow
            use(xlink:href="#icon-arrow")
        span {{ title }}
</template>

<script>
export default {
    name: 'component_link',
    props: {
        title: { type: String, default: 'Ver más' },
        route: { type: Object, requiered: true },
        alt: { type: Boolean, default: false }
    }
}
</script>
