<template lang="pug">
    div(:class="{ 'alt' : alt }").heading.margin-bottom--lg
        h2.text--uppercase.text--ls-sm
            span {{ title }}
</template>

<script>
export default {
    name: 'component_heading',
    props: {
        title: { type: String, default: 'Heading' },
        alt: { type: Boolean, default: false }
    }
}
</script>
