import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import machinery from './machinery'

export default new Vuex.Store({
  state: {
    deviceType: null,
    machinery: machinery
  },
  getters: {
    deviceType(state) {
      return state.deviceType
    },
    machinery(state) {
      return state.machinery
    }
  },
  mutations: {
    setDeviceType(state, deviceType) {
      state.deviceType = deviceType
    }
  },
  actions: {

  }
})