const machinery = [
    {
        pk: 11,
        img: "/img/machinery/Grua200.png",
        carga: 200,
        altura: 50,
        type: "gruas"
    },
    {
        pk: 10,
        img: "/img/machinery/Grua 100 ton.png",
        carga: 100,
        altura: 60,
        extension: 30,
        type: "gruas"
    },
    {
        pk: 7,
        img: "/img/machinery/Grua 45 ton.png",
        carga: 45,
        altura: 40,
        extension: 10,
        type: "gruas"
    },
    {
        pk: 9,
        img: "/img/machinery/Grua30.png",
        carga: 30,
        altura: 27,
        extension: 13,
        type: "gruas"
    },
    {
        pk: 8,
        img: "/img/machinery/Grua18.png",
        carga: 18,
        altura: 20,
        type: "gruas"
    },
    {
        pk: 12,
        img: "/img/machinery/Komatsu.png",
        carga: 6,
        altura: 20,
        type: "gruas"
    },
    {
        pk: 6,
        img: "/img/machinery/Grua 5 Ton.png",
        carga: 5,
        altura: 20,
        type: "gruas"
    },
    {
        pk: 4,
        img: "/img/machinery/Grua 4.9 ton.png",
        carga: 5,
        altura: 20,
        type: "gruas"
    },
    {
        pk: 5,
        img: "/img/machinery/Grua 2 4.9 ton.png",
        carga: 5,
        altura: 17,
        type: "gruas"
    },
    {
        pk: 3,
        img: "/img/machinery/Titan 15 ton 21m.png",
        carga: 15,
        altura: 21,
        type: "titanes"
    },
    {
        pk: 2,
        img: "/img/machinery/Titan1.png",
        carga: 14,
        altura: 21,
        type: "titanes"
    },
    {
        pk: 1,
        img: "/img/machinery/Titan7.png",
        carga: 7,
        altura: 17,
        type: "titanes"
    },
    {
        pk: 13,
        img: "/img/machinery/Retroexcavadora.png",
        traccion: "4x4",
        cucharon: 0.96,
        hp: 85,
        type: "excavadoras"
    },
    {
        pk: 14,
        img: "/img/machinery/Excavadora.png",
        traccion: "4x4",
        cucharon: 0.96,
        hp: 85,
        type: "excavadoras"
    }
]
  
export default machinery