<template lang="pug">
  #app
    svg.off_screen
      symbol(id="icon-imagotipo" viewBox="0 0 205.2 52.3")
        title Grúas Hércules
        g
          path(d="M22.6,0L0,13v26.2l22.6,13.1l22.7-13.1V13L22.6,0z M22.6,6.1l10,5.8h-5.8v15.9h-8.4V11.9h-5.8L22.6,6.1z M5.3,16.1l4.1-2.4v24.8l-4.1-2.4V16.1z M22.6,46.1l-10-5.8h5.8V36h8.4v4.3h5.8L22.6,46.1z M40,36.1l-4.2,2.4V13.7l4.2,2.4V36.1z")
          g
            path(d="M67,37.7H48.4V14.6h18.4v5.9H55.5v3h10.4v5.3H55.5v3H67V37.7z")
            path(d="M83.1,37.7l-6-7.1h-0.5v7.1h-7.1V14.6h12.6c5,0,8.3,3.2,8.3,7.9c0,3.7-2,6.4-5.3,7.6l6.6,7.7H83.1z M76.6,25.1h3.9c1.8,0,2.9-0.9,2.9-2.4c0-1.3-1.1-2.3-2.9-2.3h-3.9V25.1z")
            path(d="M116.9,29.2c-1,5.6-5.5,9-12,9c-7,0-12.5-5.3-12.5-12.1c0-6.8,5.5-12.1,12.5-12.1c5.9,0,11,3.4,12,8.8l-7.1,1.7c-0.6-2.7-2.3-4.1-4.9-4.1c-3,0-5.3,2.4-5.3,5.7s2.4,5.7,5.3,5.7c2.5,0,4.2-1.5,4.9-4.3L116.9,29.2z")
            path(d="M141,27.2c0,6.8-4.3,11-10.7,11c-6.6,0-11-4.2-11-11V14.6h7.2v12.6c0,3,1.5,4.6,3.8,4.6c2.1,0,3.5-1.7,3.5-4.6V14.6h7.2V27.2z")
            path(d="M161.6,37.7h-17.7V14.6h7.1v17h10.6V37.7z")
            path(d="M182.5,37.7h-18.6V14.6h18.4v5.9H171v3h10.4v5.3H171v3h11.5V37.7z")
            path(d="M205.1,20.8l-6.9,1.4c-0.5-2.2-2.1-2.7-3.2-2.7c-1.2,0-2.1,0.6-2.1,1.6c0,0.6,0.2,1.1,1.1,1.3l4.6,1.2c4.4,1.1,6.8,3.1,6.8,6.9c0,5.6-5.4,7.8-10,7.8c-5.8,0-10.1-2.9-10.8-7.2l7.2-1.3c0.5,2,1.8,2.9,3.6,2.9c1.3,0,2.4-0.5,2.4-1.6c0-0.6-0.3-1-1.3-1.3l-4.8-1.2c-4.1-1-6.4-3.1-6.4-6.8c0-5,4.3-7.7,9.7-7.7C200.4,14.1,204.2,16.4,205.1,20.8z")
            polygon(points="54.7,12.5 57.5,6.8 65.9,6.8 61.3,12.5")
      symbol(id="icon-isotipo" viewBox="0 0 76.1 87.9")
        title Grúas Hércules
        path(d="M38,0L0,21.9v44l38,22l38.1-22v-44L38,0z M38,10.3L54.9,20h-9.7v26.7H31V20h-9.7L38,10.3zM8.9,27.1l6.9-4v41.7l-6.9-4V27.1z M38,77.5l-16.9-9.7H31v-7.3h14.2v7.3H55L38,77.5z M67.2,60.8l-7,4V23.1l7,4V60.8z")
      symbol(id="icon-polygon" viewBox="0 0 43.3 50")
        polygon(points="0,12.5 0,37.5 21.6,50 43.3,37.5 43.3,12.5 21.6,0")
      symbol(id="icon-polygon-frame" viewBox="0 0 43.3 50")
        path(d="M21.6,50L0,37.6V12.5L21.6,0l21.6,12.5v25.1L21.6,50z M8.4,32.7l13.3,7.7L35,32.7V17.3L21.6,9.6L8.4,17.3V32.7z")
      symbol(id="icon-arrow" viewBox="0 0 24 24")
        path(d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z")
      symbol(id="icon-facebook" viewBox="0 0 32 32")
        path(d="M31.997 15.999c0-8.836-7.163-15.999-15.999-15.999s-15.999 7.163-15.999 15.999c0 7.985 5.851 14.604 13.499 15.804v-11.18h-4.062v-4.625h4.062v-3.525c0-4.010 2.389-6.225 6.043-6.225 1.75 0 3.581 0.312 3.581 0.312v3.937h-2.017c-1.987 0-2.607 1.233-2.607 2.498v3.001h4.437l-0.709 4.625h-3.728v11.18c7.649-1.2 13.499-7.819 13.499-15.804z")
      symbol(id="icon-youtube" viewBox="0 0 32 32")
        path(d="M31.327 8.273c-0.386-1.353-1.431-2.398-2.756-2.777l-0.028-0.007c-2.493-0.668-12.528-0.668-12.528-0.668s-10.009-0.013-12.528 0.668c-1.353 0.386-2.398 1.431-2.777 2.756l-0.007 0.028c-0.443 2.281-0.696 4.903-0.696 7.585 0 0.054 0 0.109 0 0.163l-0-0.008c-0 0.037-0 0.082-0 0.126 0 2.682 0.253 5.304 0.737 7.845l-0.041-0.26c0.386 1.353 1.431 2.398 2.756 2.777l0.028 0.007c2.491 0.669 12.528 0.669 12.528 0.669s10.008 0 12.528-0.669c1.353-0.386 2.398-1.431 2.777-2.756l0.007-0.028c0.425-2.233 0.668-4.803 0.668-7.429 0-0.099-0-0.198-0.001-0.297l0 0.015c0.001-0.092 0.001-0.201 0.001-0.31 0-2.626-0.243-5.196-0.708-7.687l0.040 0.258zM12.812 20.801v-9.591l8.352 4.803z")
    include_header
    main
      transition(name="page_transition")
        keep-alive
          router-view
</template>

<script>
import ress from '@/plugins/ress'
import Header from '@/views/includes/Header'

export default {
  name: 'app',
  components: {
    include_header: Header
  },
  metaInfo() {
    return {
      title: '',
      titleTemplate: 'Hércules | %s',
      meta:[
        {vmid: 'dtn', name:'description', content: 'Las mejores máquinas, especializadas para levantar y mover cargas pesadas.'},
        {vmid: 'kw', name:'keywords', content: 'Grúas Hércules, Hércules, grúas, titanes, excavadoras, renta de maquinaria para construcción, renta de grúas, renta de excavadoras, renta de titanes, Morelia, grúas en morelia, grúas telescópicas, grúas articuladas'},
        {vmid: 'inm', itemprop:'name', content: 'Hércules | Fuerza y altura a tu servicio'},
        {vmid: 'idtn', itemprop:'description', content: 'Las mejores máquinas, especializadas para levantar y mover cargas pesadas.'},
        {vmid: 'ptt', property:'og:title', content: 'Hércules | Fuerza y altura a tu servicio'},
        {vmid: 'purl', property:'og:url', content: 'https://gruashercules.mx/'},
        {vmid: 'pdtn', property:'og:description', content: 'Las mejores máquinas, especializadas para levantar y mover cargas pesadas.'},
        {vmid: 'tdtn', name:'twitter:description', content: 'Las mejores máquinas, especializadas para levantar y mover cargas pesadas.'},
        {vmid: 'twtt', name:'twitter:title', content: 'Hércules | Fuerza y altura a tu servicio'},
        {itemprop:'image', content:'https://gruashercules.mx/img/social.png'},
        {property:'fb:app_id', content:''},
        {property:'og:site_name', content:'Hércules'},
        {property:'og:locale', content:'es_ES'},
        {property:'og:type', content:'website'},
        {property:'og:image', content:'https://gruashercules.mx/img/social.png'},
        {name:'twitter:card', content:'summary_large_image'},
        {name:'twitter:site', content:''},
        {name:'twitter:image', content:'https://gruashercules.mx/img/social.png'}
      ]
    }
  },
  methods: {
    isTouch() {
      const deviceType = ress.deviceParser(navigator.userAgent)
      this.$store.commit('setDeviceType', deviceType)
      const devices = ress.deviceType
      return (deviceType == devices.tablet || deviceType == devices.phone)
    }
  },
  beforeMount() {
    if (!this.isTouch()) {
      document.body.classList.add('no_touch')
    }
  }
}
</script>

<style lang="stylus" src="@/assets/styles/index.styl"/>
