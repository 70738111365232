<template lang="pug">
  layout_page(imgCorner="/img/img-home-polygon.jpg")
    template(v-slot:header)
      section.header.full_screen
        imageParallax(image="/img/headers/home.jpg" mask="/img/headers/home-mask.jpg")
        .column.flex_content--vertical.jc_center.ai_center
          hgroup.font_smooth
            h1.text--ls-sm
              b.text--uppercase Fuerza y Altura
              span.text--w400 A tu servicio
            p Las mejores máquinas, especializadas para levantar y mover cargas pesadas.
    section.services.margin-bottom--xxxl
      .adjust.margin-bottom--sm
        base-heading(title="Servicios")
        p Te ofrecemos renta de maquinaria para la construcción con personal capacitado. Por hora, día o semana, los 365 días del año.
      article.machinery
        .grid
          .machinery--description
            div
              .margin-bottom--xl
                h3.text--uppercase.text--ls-sm Maquinaria
                p Todo lo necesario para solucionar problemas de izaje y carga, con la experiencia y profesionalismo que nos respalda.
              base-link(:route="{name: 'machinery', params: { type: 'todas' }}" title="Ver todas")
          .grid.machinery--grid
            div
              router-link(:to="{name: 'machinery', params: { type: 'gruas' }}" alt="Grúas").machine--item
                figure(v-lazyload).image
                  .polygon
                    svg.icon
                      use(xlink:href="#icon-polygon-frame")
                  img(data-src="/img/machinery/Grua 100 ton.png" data-srcset="" alt="Grúas")
                  figcaption.off_screen
                    span Grúas
                h4.text--uppercase.text--ls-sm Grúas
            div
              router-link(:to="{name: 'machinery', params: { type: 'titanes' }}" alt="Titanes").machine--item
                figure(v-lazyload).image
                  .polygon
                    svg.icon
                      use(xlink:href="#icon-polygon-frame")
                  img(data-src="/img/machinery/Titan 15 ton 21m.png" data-srcset="" alt="Titanes")
                  figcaption.off_screen
                    span Titanes
                h4.text--uppercase.text--ls-sm Titanes
            div
              router-link(:to="{name: 'machinery', params: { type: 'excavadoras' }}" alt="Excavadoras").machine--item
                figure(v-lazyload).image
                  .polygon
                    svg.icon
                      use(xlink:href="#icon-polygon-frame")
                  img(data-src="/img/machinery/Retroexcavadora.png" data-srcset="" alt="Excavadoras")
                  figcaption.off_screen
                    span Excavadoras
                h4.text--uppercase.text--ls-sm Excavadoras
      article.operators
        .grid
          .operators--polygon
            svg(viewBox="0 0 43.3 50")
              defs
                pattern(id="background-operators" height="100%" width="100%" patternContentUnits="objectBoundingBox")
                  image(xlink:href="/img/img-home-operators.jpg" width="1" height="1" preserveAspectRatio="none")
              polygon(points="0,12.5 0,37.5 21.6,50 43.3,37.5 43.3,12.5 21.6,0" fill="url(#background-operators)")
          .operators--description
            .flex_content--vertical.jc_center
              h3.text--uppercase.text--ls-sm Operadores
              p Contamos con personal capacitado para que cada maniobra sea con precisión y con el #[b profesionalismo que nos respalda]. 
    section.benefits.margin-bottom--xxxl
      base-heading(title="Beneficios")
      .grid
        .benefit
          div
            h4 Garantía
            p Somos un equipo responsable y eficaz contando con la maquinaria necesaria que garantiza nuestro trabajo.
        .benefit
          div
            h4 Profecionalismo
            p Total compromiso, mesura, ética y responsabilidad son características de nuestros servicios.
        .benefit
          div
            h4 Calidad
            p Nuestro personal es eficiente y está capacitado para solucionar cualquier problema de izaje.
</template>
  
<script>
import Page from '@/views/layouts/Page'

export default {
  name: 'page_home',
  components: {
    layout_page: Page,
    imageParallax: () => import('@/components/ImageParallax.vue')
  },
  metaInfo() {
      return {
          title: 'Inicio',
          meta:[
              {vmid: 'dtn', name:'description', content: 'Las mejores máquinas, especializadas para levantar y mover cargas pesadas.'},
              {vmid: 'kw', name:'keywords', content: 'Grúas Hércules, Hércules, grúas, titanes, excavadoras, renta de maquinaria para construcción, renta de grúas, renta de excavadoras, renta de titanes, Morelia, grúas en morelia, grúas telescópicas, grúas articuladas'},
              {vmid: 'inm', itemprop:'name', content: 'Hércules | Fuerza y altura a tu servicio'},
              {vmid: 'idtn', itemprop:'description', content: 'Las mejores máquinas, especializadas para levantar y mover cargas pesadas.'},
              {vmid: 'ptt', property:'og:title', content: 'Hércules | Fuerza y altura a tu servicio'},
              {vmid: 'purl', property:'og:url', content: 'https://gruashercules.mx/'},
              {vmid: 'pdtn', property:'og:description', content: 'Las mejores máquinas, especializadas para levantar y mover cargas pesadas.'},
              {vmid: 'tdtn', name:'twitter:description', content: 'Las mejores máquinas, especializadas para levantar y mover cargas pesadas.'},
              {vmid: 'twtt', name:'twitter:title', content: 'Hércules | Fuerza y altura a tu servicio'},
          ]
      }
  }
}
</script>
