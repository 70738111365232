import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/pages/Home.vue'

Vue.use(Router)

const asyncScrolling = new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve({ x: 0, y: 0 })
  }, 500)
})

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path:'/maquinaria/:type?',
      name: 'machinery',
      component: () => import(/* webpackChunkName: "machines" */ './views/pages/Machinery.vue'),
      props: true
    },
    {
      path: '/contacto',
      name: 'contact',
      component: () => import(/* webpackChunkName: "contact" */ './views/pages/Contact.vue')
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return asyncScrolling
  }
})
