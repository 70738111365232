<template lang="pug">
    header.main_header
        .column
            .wrapper
                .logo_box.flex_content--vertical.jc_center
                    router-link(to="/" alt="Inicio").logo
                        svg.icon
                            use(xlink:href="#icon-imagotipo")
                        span.off_screen Grúas Hércules
                .nav_box.flex_content--vertical.jc_center
                    nav.navigation.font_smooth
                        ul.flex_content--horizontal.ai_center.jc_flex-end
                            li
                                router-link(to="/" alt="Inicio")
                                    span.text--w700.text--uppercase.text--ls-sm Inicio
                            li
                                router-link(:to="{name: 'machinery'}" alt="Maquinaria").link-machinery
                                    span.text--w700.text--uppercase.text--ls-sm Maquinaria
                            li
                                router-link(to="/contacto" alt="Contacto")
                                    span.text--w700.text--uppercase.text--ls-sm Contacto
                .menu_box.flex_content--vertical.jc_center.ai_center
                    .main_menu
                        .main_menu--overlay
                        .main_menu--content
                            .content
                                div.flex_content--vertical.jc_center
                                    ul
                                        li
                                            router-link(
                                                to="/"
                                                alt="Inicio"
                                                @click.native="closeMenu")
                                                span.text--w700.text--uppercase.text--ls-sm.text--md Inicio
                                        li
                                            router-link(
                                                :to="{name: 'machinery', params: { type: 'todas' }}"
                                                alt="Maquinaria"
                                                @click.native="closeMenu")
                                                span.text--w700.text--uppercase.text--ls-sm.text--md Maquinaria
                                        li
                                            router-link(
                                                to="/contacto"
                                                alt="Contacto"
                                                @click.native="closeMenu")
                                                span.text--w700.text--uppercase.text--ls-sm.text--md Contacto
                        button.main_menu--button
                            span.off_screen Menú
                            i.bar.top
                            i.bar.bottom
</template>

<script>
export default {
    name: 'include_header',
    data() {
        return {
            lastScrollTop: 0,
            deltaScroll: 10,
            eventType: '',
            menu: null,
            button: null,
            isOpen: false
        }
    },
    methods: {
        isTouch() {
            const deviceType = this.$store.getters.deviceType
            return (deviceType == 'tablet' || deviceType == 'phone')
        },
        init() {
            window.addEventListener('scroll', () => { this.validateScroll() }, { passive: true })
            this.eventType = this.isTouch() ? 'touchstart' : 'click'
            this.menu = this.$el.querySelector('.main_menu--content')
            this.menu.addEventListener(this.eventType, event => { event.stopPropagation() }, { passive: true })
            this.button = this.$el.querySelector('.main_menu--button')
            this.button.addEventListener(this.eventType, this.handlingMenu, { passive: true })
        },
        openMenu() {
            this.$el.classList.add('main_menu--is_open')
            document.body.classList.add('no_scroll')
            document.addEventListener(this.eventType, this.handlingMenu, { passive: true })
            this.isOpen = true
        },
        closeMenu() {
            this.$el.classList.remove('main_menu--is_open')
            document.body.classList.remove('no_scroll')
            document.removeEventListener(this.eventType, this.handlingMenu)
            this.isOpen = false
        },
        handlingMenu(event) {
            event.stopPropagation()
            if (this.isOpen) {
                this.closeMenu()
            } else {
                this.openMenu()
            }
        },
        validateScroll() {
            let doc = document.documentElement
            let scrollTop = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)
            if (scrollTop >= this.deltaScroll)
                this.$el.classList.add('main_header--is_scroll')
            else
                this.$el.classList.remove('main_header--is_scroll')
            if (Math.abs(this.lastScrollTop - scrollTop) <= this.deltaScroll)
                return
            if (scrollTop > this.lastScrollTop && scrollTop > this.$el.clientHeight)
                this.$el.classList.add('main_header--is_hide')
            else
                this.$el.classList.remove('main_header--is_hide')
            this.lastScrollTop = scrollTop
        }
    },
    mounted() {
        this.init()
    }
}
</script>