<template lang="pug">
  .layout_page
    slot(name="header")
    .container
      .column
        .corner(v-if="imgCorner")
          svg(viewBox="0 0 6.9 12")
            defs
              pattern(id="background" height="100%" width="100%" patternContentUnits="objectBoundingBox")
                image(:xlink:href="imgCorner" width="1" height="1" preserveAspectRatio="none")
            polygon(points="6.9,0 0,0 0,8 6.9,12" fill="url(#background)")
        .content
          slot
          include_callToAction(v-if="callToAction")
          include_footer
</template>

<script>
export default {
  name: 'layout_page',
  props: {
    imgCorner: { type: String },
    callToAction: { type: Boolean, default: true }
  },
  components: {
    include_callToAction: () => import('@/views/includes/CallToAction'),
    include_footer: () => import('@/views/includes/Footer')
  }
}
</script>